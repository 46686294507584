"use strict";

(function () {
  const scriptInit = () => {
    window.addEventListener("message", (message) => {
      const {event, status, error} = message.data;
      if (event === "amz-form") {
        sendCustomGaEvent(status, error);
      }
    });
  };

  const getDataForEvent = (status, error) => {
    const {host, href, pathname} = window.location;

    let software = "sample_";
    switch (host) {
      case "sellerhook.com":
      case "lp.sellerhook.com":
        software += "report";
        break;
      case "analysis.sellerhook.com":
        software += "analysis";
        break;
      default:
        const subdomain = host.split(".")[0];
        software += subdomain;
    }

    const category = `${software}|EmailForm`;
    const action = [
      status ? "Success" : "Error",
      error ? error : "",
      software,
      pathname !== "/" ? pathname.replace(/^\/|\/$/g, "") : ""
    ].filter(Boolean).join("|")
    return {action, category, label: href};
  };

  const sendCustomGaEvent = (status, error) => {
    const {action, category, label} = getDataForEvent(status, error);

    if (window.gtag) {
      gtag("event", action, {
        event_category: category,
        event_label: label,
        value: "",
        transport_type: "beacon",
      });
    } else {
      console.error("gtag is undefined!");
    }
  };

  document.addEventListener("DOMContentLoaded", function () {
    scriptInit();
  });
})()
